import React from 'react'
import './Button.css'
const Button = (props) => {
    return (
        <>
            <button className='buttonComponent' {...props}/>
        </>
    )
}

export default Button
