import React from 'react'
import Button from '../Button/Button'
import './ButtonsConhecaTambem.css'
const ButtonsConhecaTambem = ({cidade}) => {
    return (
        <div className='container-ct'>
            <strong>Baby+ 🪄🎊</strong>
            <Button onClick={() => window.location.replace(`https://aprincipalbb.com.br/cadastro/?cidade=${cidade}&apbm=apbm`)}
            >CONSULTE SEU SALDO CASHBACK
            </Button>
            <strong>Conheça tambem 👀</strong>
            <Button onClick={() => window.location.replace('https://aprincipalbb.com.br/trabalhe.php')}
            >TRABALHE CONOSCO
            </Button>
            <Button onClick={() => window.location.replace('https://aprincipalbb.com.br/descobriu-ganhou/')}
            >DESCOBRIU GANHOU
            </Button>
        </div>
    )
}

export default ButtonsConhecaTambem
