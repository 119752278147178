import React, { useEffect, useState } from 'react'
import {  Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import coracao from '../assets/coracao.png'
import imgCampanha from '../assets/aprincipal.jpeg'
import ButtonsConhecaTambem from '../components/ButtonsConhecaTambem/ButtonsConhecaTambem'
import ButtonsWhatsAppLojas from '../components/ButtonsWhatsAppLojas/ButtonsWhatsAppLojas'
import RegisterPromotions from '../components/RegisterPromotions/RegisterPromotions'
import SocialMidia from '../components/SocialMidia/SocialMidia'
import { infoCidade } from '../utils/infoCidade'
import './Links.css'
const Links = () => {
    const [cidade, setCidade] = useState('')
    const [infoBotoes, setInfoBotoes] = useState('')
    const [linkInstagran, setLinkInstagram] = useState('')
    const params = useParams()
    const nomeCidadeUrl = params.id
    useEffect(() => {
        const infoLojas = async () => {
            const info = await infoCidade(nomeCidadeUrl)
            if (info[0].cidade && info[0].linkIntagram) {
                let vAux = info[0].cidade.split(' ')
                setLinkInstagram(info[0].linkIntagram)
                setCidade(vAux[0])
            }
           return setInfoBotoes(info)
        }
        infoLojas()
    }, [nomeCidadeUrl])
    return (
        <Container>
            <Row className="justify-content-center">
                <img className='imgCoracao' src={coracao} alt="Coração a principal" />
                <div className='divTxtCampanha'>
                    <strong className='principalStrong'>A Principal</strong>
                    <strong className='principalStrong'>Bebê & Mamãe</strong>
                    <span>{cidade}</span>
                </div>
            </Row>
            <Row>
                <SocialMidia linkInstagram={linkInstagran}/>
            </Row>
            <Row className="justify-content-center">
                <img className='imgCampanha' src={imgCampanha} alt="imagem da campanha" />
            </Row>
            <main>
                {/* <RegisterPromotions /> */}
                <ButtonsWhatsAppLojas cidade={cidade} infoBotoes={infoBotoes} />
                <ButtonsConhecaTambem cidade={cidade}/>
            </main>
        </Container>
    )
}

export default Links
