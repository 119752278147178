import Links from "./pages/Links";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/:id' element={<Links />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
