import api from "../services/api";

export const infoCidade = async (cidade) => {
    const response = await api.get('/links/request.php', {
        params: { entity: 'links', entityData: true, entityFunction: 'getLocalidade' }
    })
    const { data } = response
    if (data?.status === 'success' && data?.data) {

        const resp = data.data.filter(item => {
            const nomeSemAcento = item.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
            const cidadeTemp = cidade.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
            console.log(nomeSemAcento);
            console.log(cidadeTemp);
            if (cidadeTemp === nomeSemAcento) {
                return item
            }
        })
        const response2 = await api.get('/links/request.php', {
            params: { entity: 'links', entityData: {id:resp[0].id }, entityFunction: 'getInfoLojas' }
        })
        return response2.data.data
    }
    return false
}